.countup-wrapper {
  padding: 20px;
  margin: 5px;
  font-size: 1.3em;
  color: $white;
  background-color: $cyan;
  border-radius: 10px;

  .countup {
    display: block;
    font-size: 3em;
    font-weight: 700;
    //color: $dark-blue;
  }
}
