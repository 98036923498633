.navbar.navbar-dark {
  min-height: 60px;
  padding-top: 0;
  padding-bottom: 0;
  font-family: "Open Sans", sans-serif;

  .navbar-toggler {
    margin-right: 10px;
  }

  .navbar-brand {
    max-width: 75%;
    padding: 0;
  }

  .navbar-collapse {
    flex-basis: 90%;

    .nav-item {
      margin-left: 20px;

      a {
        color: $white;
      }
    }
  }
}
