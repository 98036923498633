.logos {
  position: relative;
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    position: relative;
    padding: 0;
    //cursor: pointer;
    transition: all .5s ease;

    img {
      top: 0;
      left: 0;
      //filter: greyscale(90%);
      filter: unquote("grayscale(100%) contrast(100%)");
      transition: all .5s ease;
      transform: scale(1);
    }
  }

  .active {
    img {
      position: absolute;
      z-index: 100;
      background-color: $white;
      filter: none;
      transform: scale(2);
    }
  }
}
