body {
  padding-top: 60px;
}

.no-padding {
  padding-right: 0;
  padding-left: 0;
}

h1 {
  font-size: 24px;
}

@include media-breakpoint-up(sm) {
  h1 {
    font-size: $h1-font-size;
  }
}
