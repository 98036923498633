.product-box {
  text-align: center;

  .product-box__icon-wrapper {
    margin-bottom: 20px;
  }

  .product-box__header {
    font-size: 20px;
    color: $dark-blue;
  }

  .product-box__description {
    line-height: 1.1em;
  }
}
