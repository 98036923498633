.container-cyan {
  color: $white;
  background-color: $cyan;

  .btn-outline-light {
    color: $white;
    border-color: $white;

    &:hover {
      color: $cyan;
      background-color: $white;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $white;
  }
}
