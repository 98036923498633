@import "variables";

@font-face {
  font-family: 'ae-icon-font';
  src:  url('#{$font-path}/ae-icon-font.eot?bakb1j');
  src:  url('#{$font-path}/ae-icon-font.eot?bakb1j#iefix') format('embedded-opentype'),
    url('#{$font-path}/ae-icon-font.ttf?bakb1j') format('truetype'),
    url('#{$font-path}/ae-icon-font.woff?bakb1j') format('woff'),
    url('#{$font-path}/ae-icon-font.svg?bakb1j#ae-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ae-icon-"], [class*=" ae-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ae-icon-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ae-icon-contact {
  &:before {
    content: $ae-beratung;
  }
}
.ae-icon-print {
  &:before {
    content: $ae-print;
  }
}
.ae-icon-text {
  &:before {
    content: $ae-text;
  }
}
.ae-icon-training {
  &:before {
    content: $ae-schulung;
  }
}
.ae-icon-web {
  &:before {
    content: $ae-web;
  }
}
.ae-icon-seo {
  &:before {
    content: $ae-seo;
  }
}
