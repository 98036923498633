.v2 {
  .btn-light {
    background-color: $orange;
    border-color: $orange;
  }

  .container-cyan {
    .btn-outline-light {
      background-color: $orange;
      border-color: $orange;
    }
  }
}
