.stage {
  background-color: $dark-blue;

  @include media-breakpoint-up(md) {
    background: none;
  }

  .stage__img {
    width: 100%;
  }

  .stage__content--top {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    font-size: 28px;
    color: $white;
    transform: translate(-50%,-50%);
  }

  .stage__content {
    width: 100%;
    color: $white;

    .stage__header {
      margin-top: $spacer * 3;
      color: $white;
    }

    @include media-breakpoint-up(sm) {

    }

    @include media-breakpoint-up(md) {
      @include vertical-horizontal-center();

      .stage__header {
        margin-top: 0;
        color: $white;
      }
    }

    @include media-breakpoint-up(lg) {
      width: auto;
    }

    .btn {
      margin-top: 30px;
    }
  }
}
