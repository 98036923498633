@mixin vertical-horizontal-center($position: absolute) {
  position: $position;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin vertical-center($position: absolute) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}
