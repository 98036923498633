@import "variables";

// Include current bootstrap functions and variables
@import "../bootstrap/scss/functions";
@import "../bootstrap/scss/variables";
// Load own Variables
@import "bootstrap/variables";

// Include current complete bootstrap
@import "../bootstrap/scss/bootstrap";
// Include own bootstrap/default
@import "bootstrap/default";
// Include own mixins/default
@import "mixins/default";
// Include fonts
@import "fonts";
// Include root file
@import "root";
// Include panel
@import "panel";

// Include container file
@import "container";
// Include cookie statement popup
@import "cookie-statement-popup";
// Include stage file
@import "stage";
// Include product-box file
@import "product-box";
// Include offer file
@import "offer";
// Include contact file
@import "contact";
// Include data-privacy file
@import "data-privacy";
// Include map file
@import "map";
// Include footer file
@import "footer";
// Include plugins/logos file
@import "plugins/logos";
// Include plugins/countup file
@import "plugins/countup";


@import "v2";
