// Path
$font-path: "../fonts";

// Colors
$white:  #fff;
$dark-blue: #172355;
$darker-blue: #01aad3;
$cyan: #00aad2;
$dark-grey: #6f6f6f;
$middle-grey: #6b6c6e;
$light-grey: #e6e6e6;
$orange: #ef8200;
$font-color: #707070;
