@import "variables";

@font-face {
  font-family: 'ae-font';
  src:  url('#{$font-path}/ae-font.eot?2tu106');
  src:  url('#{$font-path}/ae-font.eot?2tu106#iefix') format('embedded-opentype'),
    url('#{$font-path}/ae-font.ttf?2tu106') format('truetype'),
    url('#{$font-path}/ae-font.woff?2tu106') format('woff'),
    url('#{$font-path}/ae-font.svg?2tu106#ae-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ae-"], [class*=" ae-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ae-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ae-mail1 {
  &:before {
    content: $ae-mail1;
  }
}
.ae-at {
  &:before {
    content: $ae-at;
  }
}
.ae-phone {
  &:before {
    content: $ae-phone;
  }
}
.ae-letter1 {
  &:before {
    content: $ae-letter1;
  }
}
.ae-arrow1-right {
  &:before {
    content: $ae-arrow1-right;
  }
}
.ae-arrow1-left {
  &:before {
    content: $ae-arrow1-left;
  }
}
.ae-contact1 {
  &:before {
    content: $ae-contact1;
  }
}
.ae-mail2 {
  &:before {
    content: $ae-mail2;
  }
}
.ae-eye {
  &:before {
    content: $ae-eye;
  }
}
.ae-calendar1 {
  &:before {
    content: $ae-calendar1;
  }
}
.ae-letter2 {
  &:before {
    content: $ae-letter2;
  }
}
.ae-speech-bubble {
  &:before {
    content: $ae-speech-bubble;
  }
}
.ae-home1 {
  &:before {
    content: $ae-home1;
  }
}
.ae-facebook {
  &:before {
    content: $ae-facebook;
  }
}
.ae-twitter {
  &:before {
    content: $ae-twitter;
  }
}
.ae-rss-feed {
  &:before {
    content: $ae-rss-feed;
  }
}
.ae-faq1 {
  &:before {
    content: $ae-faq1;
  }
}
.ae-card {
  &:before {
    content: $ae-card;
  }
}
.ae-faq2 {
  &:before {
    content: $ae-faq2;
  }
}
.ae-loupe {
  &:before {
    content: $ae-loupe;
  }
}
.ae-letter3 {
  &:before {
    content: $ae-letter3;
  }
}
.ae-22 {
  &:before {
    content: $ae-22;
  }
}
.ae-statement {
  &:before {
    content: $ae-statement;
  }
}
.ae-calendar2 {
  &:before {
    content: $ae-calendar2;
  }
}
.ae-statistic1 {
  &:before {
    content: $ae-statistic1;
  }
}
.ae-statistic2 {
  &:before {
    content: $ae-statistic2;
  }
}
.ae-letter4 {
  &:before {
    content: $ae-letter4;
  }
}
.ae-mobile1 {
  &:before {
    content: $ae-mobile1;
  }
}
.ae-trolley1 {
  &:before {
    content: $ae-trolley1;
  }
}
.ae-trolly2 {
  &:before {
    content: $ae-trolly2;
  }
}
.ae-pdf {
  &:before {
    content: $ae-pdf;
  }
}
.ae-hook {
  &:before {
    content: $ae-hook;
  }
}
.ae-statistic3 {
  &:before {
    content: $ae-statistic3;
  }
}
.ae-arrow2-right {
  &:before {
    content: $ae-arrow2-right;
  }
}
.ae-arrow2-left {
  &:before {
    content: $ae-arrow2-left;
  }
}
.ae-contact2 {
  &:before {
    content: $ae-contact2;
  }
}
.ae-home2 {
  &:before {
    content: $ae-home2;
  }
}
.ae-trash {
  &:before {
    content: $ae-trash;
  }
}
.ae-add {
  &:before {
    content: $ae-add;
  }
}
.ae-edit {
  &:before {
    content: $ae-edit;
  }
}
.ae-spin {
  &:before {
    content: $ae-spin;
  }
}
.ae-arrow3-right {
  &:before {
    content: $ae-arrow3-right;
  }
}
.ae-arrow3-left {
  &:before {
    content: $ae-arrow3-left;
  }
}
.ae-monitor {
  &:before {
    content: $ae-monitor;
  }
}
.ae-mobile2 {
  &:before {
    content: $ae-mobile2;
  }
}
.ae-camera {
  &:before {
    content: $ae-camera;
  }
}
.ae-pin {
  &:before {
    content: $ae-pin;
  }
}
.ae-on1 {
  &:before {
    content: $ae-on1;
  }
}
.ae-phone2 {
  &:before {
    content: $ae-phone2;
  }
}
.ae-contact {
  &:before {
    content: $ae-contact;
  }
}
.ae-brush {
  &:before {
    content: $ae-brush;
  }
}
.ae-printer1 {
  &:before {
    content: $ae-printer1;
  }
}
.ae-on2 {
  &:before {
    content: $ae-on2;
  }
}
.ae-home3 {
  &:before {
    content: $ae-home3;
  }
}
.ae-picture {
  &:before {
    content: $ae-picture;
  }
}
.ae-paint {
  &:before {
    content: $ae-paint;
  }
}
.ae-googleplus {
  &:before {
    content: $ae-googleplus;
  }
}
.ae-instagram1 {
  &:before {
    content: $ae-instagram1;
  }
}
.ae-instagram2 {
  &:before {
    content: $ae-instagram2;
  }
}
.ae-youtube {
  &:before {
    content: $ae-youtube;
  }
}
.ae-map {
  &:before {
    content: $ae-map;
  }
}
.ae-web {
  &:before {
    content: $ae-web;
  }
}
.ae-mouse {
  &:before {
    content: $ae-mouse;
  }
}
.ae-position {
  &:before {
    content: $ae-position;
  }
}
.ae-mail3 {
  &:before {
    content: $ae-mail3;
  }
}
.ae-seo {
  &:before {
    content: $ae-seo;
  }
}
.ae-learn {
  &:before {
    content: $ae-learn;
  }
}
.ae-print {
  &:before {
    content: $ae-print;
  }
}
.ae-responsive {
  &:before {
    content: $ae-responsive;
  }
}
.ae-contact3 {
  &:before {
    content: $ae-contact3;
  }
}
.ae-printer2 {
  &:before {
    content: $ae-printer2;
  }
}
