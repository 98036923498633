.modal-full {
  width: 100%;
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;

  .modal-header {
    padding: 13px;

    background-color: $dark-blue;
    * {
      color: $white;
    }

    .close {
      opacity: 1;
    }
  }

  .modal-content {
    @include make-container();

    max-width: 500px;

    @include media-breakpoint-up(sm) {
      max-width: 500px;
    }

    @include media-breakpoint-up(md) {
      max-width: 700px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 800px;
    }

    height: auto;
    min-height: 100%;
    padding: 0;
    border-radius: 0;

    .carousel-caption {
      position: relative;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 10px;
      color: $dark-blue;
    }

    .modal-body {
      padding: 20px 0;
    }
  }
}
