.cookie-statement-popup {
  position: fixed;
  bottom: 0;
  z-index: 100;
  display: none;
  width: 100%;
  font-size: 11px;
  color: #fff;
  text-align: center;
  background: $dark-blue;

  @include media-breakpoint-up(sm) {
    font-size: 14px;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  .btn {
    font-size: 11px;
  }
}
