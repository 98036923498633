button {
  cursor: pointer;
}

.btn-outline-light,
.btn-outline-dark {
  color: $white;
  background: none;
  border-color: $white;
}

.btn-outline-light:hover {
  color: $cyan;
  background-color: $white;
}

.btn-outline-dark:hover {
  color: $dark-blue;
  background-color: $white;
}
