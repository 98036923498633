@font-face {
  font-family: "tuffyregular";
  src: url("#{$font-path}/Tuffy-webfont.eot");
  src: url("#{$font-path}/Tuffy-webfont.eot?#iefix") format("embedded-opentype"), url("#{$font-path}/Tuffy-webfont.woff") format("woff"), url("#{$font-path}/Tuffy-webfont.ttf") format("truetype"), url("#{$font-path}/Tuffy-webfont.svg#tuffyregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tuffybold";
  src: url("#{$font-path}/Tuffy_Bold-webfont.eot");
  src: url("#{$font-path}/Tuffy_Bold-webfont.eot?#iefix") format("embedded-opentype"), url("#{$font-path}/Tuffy_Bold-webfont.woff") format("woff"), url("#{$font-path}/Tuffy_Bold-webfont.ttf") format("truetype"), url("#{$font-path}/Tuffy_Bold-webfont.svg#tuffybold") format("svg");
  font-weight: normal;
  font-style: normal;
}
