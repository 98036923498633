.panel-accordion {
  border-bottom: 1px solid $middle-grey;

  &.panel-last {
    border: 0;
  }

  .panel-title {
    position: relative;
    margin: 0;
    font-size: 30px;

    a {
      display: block;
      padding: 20px 0;
      color: $middle-grey;
      text-decoration: none;

      * {
        pointer-events: none;
      }

      .fa {
        position: absolute;
        top: 15px;
        right: 10px;
        font-size: 1.4em;
      }
    }

    .collapsed {
      .fa::before {
        content: "\f105";
      }
    }
  }

  .panel-body {
    padding-bottom: 20px;
  }
}

.panel-skill {
  .panel-skill__cite {
    margin-bottom: 5px;
    font-style: italic;
  }

  .panel-skill__header {
    margin-bottom: 5px;
    font-weight: 700;
  }
}
