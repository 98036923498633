.offer {
  h3 {
    font-size: 24px;
    font-weight: 400;
  }

  line-height: 1.2;

  .btn {
    margin-top: 20px;
  }
}
