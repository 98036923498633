.data-privacy {
  .section {
    margin-bottom: 2 * $spacer;
  }

  .sub-section {
    margin-bottom: 1.5 * $spacer;
  }

  ol,
  ul {
    margin-top: $spacer;
  }
}
