$theme-colors: (
  primary: $cyan,
  secondary: $dark-blue,
  light: $cyan,
  dark: $dark-blue,
);

$enable-rounded:            false;
$enable-shadows:            false;
$enable-transitions:        true;
$enable-grid-classes:       true;
$enable-print-styles:       true;

$body-bg:       $white;
$body-color:    $font-color;

$font-family-base: "tuffyregular", "arial";

$link-color:            $dark-blue;
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;

$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

$headings-font-family:   $font-family-base;

$headings-color:    $dark-blue;

$jumbotron-bg:  $cyan;

$carousel-control-color: $dark-blue;
